import React from "react";
import { PageHeaderProps } from "@/lib/types";

export function PageHeader({ children, pt, pb = "pb-1" }: PageHeaderProps) {
  return (
    <h1
      className={`${pt} ${pb} text-balance font-sans text-xl font-bold tracking-tight sm:text-3xl`}
    >
      {children}
    </h1>
  );
}
