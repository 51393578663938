"use client";

import { PageHeader } from "@/components/ui/page-header";
import { Button } from "@heroui/button";
import { Link } from "@heroui/link";

export default function Error({ error }: { error: Error }) {
  return (
    <div className="mt-12 lg:mt-64">
      <div className="mx-auto flex w-full max-w-5xl flex-col items-center justify-center text-center">
        <div className="flex w-full items-center justify-center">
          <PageHeader>404</PageHeader>
        </div>
        <p className="mb-4 text-xl text-black dark:text-white">
          Uh oh, this page couldn&apos;t be found.
        </p>
        <Button as={Link} href="/" color="primary">
          Go back home
        </Button>
      </div>
    </div>
  );
}
